<template>
  <div>
    <!-- search input -->
    <b-input-group class="mb-1">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model.lazy="searchword"
        placeholder="Procurar..."
        @input="search"
      />
    </b-input-group>

    <!-- tree -->
    <v-tree
      ref="tree"
      :can-delete-root="false"
      :data="treeData"
      :draggable="false"
      :halfcheck="false"
      :multiple="false"
      :tpl="tpl"
    />
  <template>
    <div v-if="treeData == null && treeDataConexao == ''">
      <b-spinner class="ml-auto" />
      <strong> Carregando...</strong>
    </div>
  </template>
  </div>
</template>

<script>
import { VTree } from 'vue-tree-halower'
import {
  BSpinner, BFormInput, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'

import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

export default {
  components: {
    VTree,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BSpinner,
  },
  data() {
    return {
      treeDataConexao: '',
      searchword: '',
      treeData: [],
    }
  },
  mounted() {
    this.treeData = this.ListaDocumento(this.$route.params.path)
  },
  methods: {
    search() {
      this.$refs.tree.searchNodes(this.searchword)
    },
    tpl(...args) {
      const { 0: node } = args
      let Retorno = ''
      let titleClass = node.selected ? 'node-title node-selected' : 'node-title'
      if (node.searched) titleClass += ' node-searched'
      if (node.type != null && node.type !== '') {
        Retorno = <span>
          <span class={titleClass} domPropsInnerHTML={node.title} onClick={() => {
            this.$refs.tree.nodeSelected(node)
          }}></span>
          <button class="btn-async text-warning border-0 cursor-pointer mr-1" onClick={() => this.DownloadDocumento(node)}>Download</button>
        </span>
      } else {
        Retorno = <span>
         <span class={titleClass} domPropsInnerHTML={node.title} onClick={() => {
           this.$refs.tree.nodeSelected(node)
         }}></span>
       </span>
      }
      return Retorno
    },
    async ListaDocumento(path) {
      Spinner.StarLoad()
      let notification = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/DocumentoNav/ListDocumento?CODUSU=${usuario.codusu}&Path=${path}`
      await apiPortalSky.post(URLpost, path).then(response => {
        if (response.status === 200) {
          this.treeData = response.data
        }
      }).catch(error => {
        this.treeDataConexao = 'Erro'
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    async DownloadDocumento(node) {
      Spinner.StarLoad()
      let Documento = null
      let notification = ''
      const Cpath = node.path
      const usuario = this.$session.get('usuario')
      const URLpost = `/DocumentoNav/DownlodDocumento?CODUSU=${usuario.codusu}&Path=${Cpath}`
      await apiPortalSky.post(URLpost, Cpath).then(response => {
        if (response.status === 200) {
          Documento = response.data
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(Documento != null){
        await UtilVUE.DownloadFile(Documento.dados, Documento.title);
      }

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tree.scss";
</style>
